void 0;
var cemf = cemf || {
    js: {}
};
cemf.js.Web = function () { }
cemf.js.Web.prototype.url = "";
cemf.js.Web.prototype.url_req = "";
cemf.js.Web.prototype.url_pix_qrcode = "";
cemf.js.Web.prototype.start_req = "";
cemf.js.Web.prototype.time_req = "";
cemf.js.Web.prototype.link = "";
cemf.js.Web.prototype.external_reference = "";
cemf.js.Web.prototype.utility = null;

cemf.js.Web.prototype.reserve = {
    hash: Math.floor(Date.now() * Math.random()).toString(23),
    get total() {
        return (this.price * this.amount).toFixed(2);
    },
    get total_pt() {
        return (this.price * this.amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });

    }
};


cemf.js.Web.prototype.start = function () {
    var self = this;
    self.utility = new cemf.js.Utility();
    self.utility.messageStart();
    self.utility.start();

    $("#phone").mask("(00) 00000-0000");
    $("#cpf").mask("000.000.000-00");

    $("#btn-copy-paste").on("click", function () {
        void 0
        $("#cod-pix").select();
        document.execCommand("copy");
        self.utility.ajaxMessage("<div class='message --white icon-thumbs-up'>Código PIX copiado</div>", 3);

    });

    $("#container-copy-paste").on("click", function () {
        void 0
        $("#cod-pix").select();
        document.execCommand("copy");
        self.utility.ajaxMessage("<div class='message --white icon-thumbs-up'>Código PIX copiado</div>", 3);

    });

}


cemf.js.Web.prototype.startRequest = function () {
    var self = this;


    var rqInterval = setInterval(request, 5000);

    function request() {
        var flashClass = "ajax_response";
        var flash = $("." + flashClass);

        void 0;

        $.ajax({
            method: "POST",
            url: self.url,
            dataType: "json",
            data: {
                external_reference: self.external_reference,
            },
            beforeSend: function () {
                void 0;
            },
            success: function (response) {
                void 0;
                void 0;


                //message
                if (response.message) {
                    self.utility.ajaxMessage(response.message, 3);

                }
                if (response.error == true) {
                    clearInterval(rqInterval);
                }

                //redirect
                if (response.redirect) {
                    window.location.href = response.redirect;
                }

                //Atualizando Inteface
                if (response.payment) {
                    $('#status').html(response.payment.status_pt);
                    $('#title-status').html(response.payment.status_title);
                    $('#info-status').html(response.payment.status_info);


                    if (response.payment.status == 'approved') {
                        clearInterval(rqInterval);
                        $("#btn-pay").addClass("none");
                        $("#btn-support").addClass("none");
                        $("#status")
                            .addClass("--green")
                            .removeClass("--color-secondary");

                        $("#btn-home").removeClass("none");


                    } else if (response.payment.status == 'pending' ||
                        response.payment.status == 'in_process') {
                        $("#btn-pay").addClass("none");
                        $("#btn-support").addClass("none");

                    } else if (response.payment.status == 'rejected' ||
                        response.payment.status == 'cancelled') {

                        clearInterval(rqInterval);
                        $("#btn-pay").removeClass("none");
                        $("#btn-support").removeClass("none");
                        $("#status")
                            .addClass("--red")
                            .removeClass("--color-secondary");


                    } else if (response.payment.status == 'refunded') {

                        clearInterval(rqInterval);
                        $("#btn-support").removeClass("none");
                        $("#btn-pay").addClass("none");


                    } else if (response.payment.status == 'charge_back') {

                        clearInterval(rqInterval);
                        $("#btn-support").removeClass("none");
                        $("#btn-pay").addClass("none");


                    } else if (response.payment.status == 'expired') {

                        clearInterval(rqInterval);
                        $("#btn-support").removeClass("none");
                        $("#btn-pay").addClass("none");
                        $("#btn-home").removeClass("none");

                    }

                }
            },
            complete: function (response) {
                void 0;

            },
            error: function (event) {

                self.utility.ajaxMessage("<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição...</div>", 5);

            }

        });
    }
}
cemf.js.Web.prototype.Mynumber = function () {
    void 0;

    var self = this;

    $input = $("#search");
    $param = $input.val();
    $btnSearch = $("#btnSearch");


    if (self.url.match(/email=/)) {
        $email = url.substring((self.url.indexOf("email=") + 6), self.url.length);
        $input.val($email);
        $param = $email

    } else if (self.url.match(/phone=/)) {
        $phone = self.url.substring((self.url.indexOf("phone=") + 6), self.url.length);
        var $aux = $("<input>");
        $aux.val($phone);
        $aux.mask("(00) 00000-0000");
        $input.val($aux.val());
        $param = $phone;

    }

    $input.on("change", function () {
        $param = $(this).val();
        if (!isNaN($param)) {
            $(this).mask("(00) 00000-0000");
        }

    });

    $input.on("keyup", function () {
        $param = $(this).val();
        if ($param.length == 0) {
            void 0
            $(this).unmask();
        }

    });


    $btnSearch.on("click", function (e) {
        e.preventDefault();

        if (self.utility.isEmail($param)) {
            sendReserves({ email: $param });
        } else if (self.utility.only_numbers($param).length > 9) {
            sendReserves({ phone: self.utility.only_numbers($param) });
        } else {
            self.utility.ajaxMessage("<div class='message info icon-warning'>Informe um e-mail ou telefone com DDD.</div>", 5);
        }

    });

    function sendReserves($data) {
        var load = $("[data-modal-loading]");
        var flashClass = "ajax_response";
        var flash = $("." + flashClass);

        $.ajax({
            method: "POST",
            url: self.url,
            dataType: "json",
            data: $data,
            beforeSend: function () {
                void 0;
                load.find("[data-info]").html("Buscando...");
                load.fadeIn('fast');
            },
            success: function (response) {
                //console.log("Success");
                //console.log(response);

                //message
                if (response.message) {
                    $("#content").html("<div class='pt-30'><div class='pt-30'><p class='flex-center --gray cf-text-5 center'>Sem resultado</div></div>");
                    self.utility.ajaxMessage(response.message, 3);
                }

                if (response.contests) {
                    var json = response.contests
                    // console.log(json);

                    $("#content").html(" ");
                    for (const property in json) {
                        obj = json[property];
                        $("#content").prepend(createCardNumbers(obj));
                    }
                }
            },
            complete: function (response) {
                //console.log("Complete");

                if (response.reload == null) {
                    load.fadeOut('fast');
                }

            },
            error: function (event) {
                load.fadeOut('fast', function () {
                    self.utility.ajaxMessage("<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição...</div>", 5);
                });
            }

        });
    }

    function createCardNumbers(obj) {
        $card = $("<div></div>");
        $card.addClass("card shadow-top-z-3 card-numbers mt-10");
        $a = $("<a></a>");
        $a.prop("href", self.link + "/" + obj.keyword);
        $resume = $("<div></div>");
        $resume.addClass("resume");

        $line = $("<div></div>");
        $line.addClass("flex row");
        $campo = $("<p></p>");
        $campo.addClass("cf-text-5 --black bold campo");
        $campo.text("Concurso");
        $answer = $("<p></p>");
        $answer.addClass("cf-text-5 answer --black");
        $answer.text(": " + obj.title);
        $line.append($campo, $answer);
        $resume.append($line);

        $line = $("<div></div>");
        $line.addClass("flex row");
        $campo = $("<p></p>");
        $campo.addClass("cf-text-5 --black bold campo");
        $campo.text("Data do Sorteio");
        $answer = $("<p></p>");
        $answer.addClass("cf-text-5 answer --black");
        $answer.text(": " + obj.date);
        $line.append($campo, $answer);
        $resume.append($line);
        
        $line = $("<div></div>");
        $line.addClass("flex row");
        $campo = $("<p></p>");
        $campo.addClass("cf-text-5 --black bold campo");
        $campo.text("Nome");
        $answer = $("<p></p>");
        $answer.addClass("cf-text-5 answer --black");
        $answer.text(": " + obj.name);
        $line.append($campo, $answer);
        $resume.append($line);

        $line = $("<div></div>");
        $line.addClass("flex row");
        $campo = $("<p></p>");
        $campo.addClass("cf-text-5 --black bold campo");
        $campo.text("Telefone");
        $answer = $("<p></p>");
        $answer.addClass("cf-text-5 answer --black");
        $answer.text(": " + obj.phone);
        $line.append($campo, $answer);
        $resume.append($line);

        $line = $("<div></div>");
        $line.addClass("status-contest");
        $circle = $("<div></div>");
        $circle.addClass("cf-text-3 --white circle mt-5 mb-5" + (obj.status == 'finished' ? " -gray" : " -blue-light"));
        $circle.text((obj.status == 'finished' ? "encerrado" : "em andamento"));
        $line.append($circle);
        $resume.append($line);

        $line = $("<div></div>");
        $line.addClass("flex row");
        $text = $("<p></p>");
        $text.addClass("cf-text-4 bold" + (obj.status == 'finished' ? " --gray" : " --blue-light"));
        $text.text((obj.status == 'finished' ? "Você concorreu com o(s) número(s):" : "Você está concorrendo com o(s) número(s):"));
        $line.append($text);
        $resume.append($line);

        $numbers = $("<div></div>");
        $numbers.addClass("numbers");
        $container = $("<div></div>");
        $container.addClass("flex row flex-center p-10");

        $p = $("<p></p>");
        $p.addClass("cf-text-6 bold" + (obj.status == 'finished' ? " --gray" : " --blue-light"));

        $n = "";
        (obj.numbers).forEach(function (element, index) {
            $n += element + "<span class='light'> | </span>"
        });
        $p.html($n);
        $container.append($p);
        $numbers.append($container);

        $a.append($resume);
        $a.append($numbers);
        $card.append($a);

        return $card;
    }

}

cemf.js.Web.prototype.steps = function () {
    void 0;
    var self = this;


    // Send Mercado Pag
    // var res_name = "";
    // var res_email = "";
    // var res_phone = "";
    // var res_amount = 1;
    // var res_total = <?= $contest->price; ?>;
    // var res_again = false;

    // var contest_price = <?= $contest->price; ?>;


    var load = $("[data-modal-loading]");

    self.reserve.amount = 1;

    void 0;

    // return;

    $step1 = $("#step-1");
    $step2 = $("#step-2");
    $step3 = $("#step-3");
    $step4 = $("#step-4")


    /* Animação CSS */

    const animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            const node = document.querySelector(element);
            //console.log(node);

            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, {
                once: true
            });
        });


    // Ao clicar no botão Próximo e Voltar
    $(".group-float a").on("click", function (e) {
        e.preventDefault();
        var step = $(this).data('source');
        void 0;
        animationSelect(step);
    });

    //Efetua a Animação
    function animationSelect(step) {
        if ($step1.is(":visible")) {
            //animateCSS("#step-1", 'slow');
            if (step == "back") {
                // window.location.href = "<?= url($contest->keyword); ?>";
                window.location.href = self.url;
            } else {
                validateAmount();
                updateAmount();
                if (self.reserve.amount <= 0) {
                    self.utility.ajaxMessage("<div class='message error'>A quantidade não por ser zero</div>", 2);
                    return;
                }
                animateCSS("#step-1", 'bounceOut').then((message) => {
                    $("#step-1").addClass("none");
                    $("#step-2").removeClass("none");
                    animateCSS("#step-2", 'bounceInRight');
                    $("#strip-1").removeClass("-gray");
                    $("#circle-2").removeClass("-gray");
                });
            }
        }

        if ($step2.is(":visible")) {
            if (step == "next") {
                var error = false;
                updateResult();
                if (self.reserve.phone == "") {
                    $("#phone").addClass("error");
                    $("#error-phone").text("Favor informar um telefone");
                    error = true;
                } else {
                    $("#phone").removeClass("error");
                    $("#error-phone").text("");
                }
                if (!self.utility.isEmail(self.reserve.email)) {
                    $("#email").addClass("error");
                    $("#error-email").text("Favor informar um email válido");
                    error = true;
                } else {
                    $("#email").removeClass("error");
                    $("#error-email").text("");

                }
                if (self.reserve.name == "" || self.utility.isName(self.reserve.name)) {
                    $("#name").addClass("error");
                    $("#error-name").text("Favor informar um nome válido");
                    error = true;
                } else {
                    $("#name").removeClass("error");
                    $("#error-name").text("");
                }

                if (error) {
                    return;
                }
            } else {
                $("#name").removeClass("error");
                $("#error-name").text("");
                $("#phone").removeClass("error");
                $("#error-phone").text("");
                $("#email").removeClass("error");
                $("#error-email").text("");
                $("#cpf").removeClass("error");
                $("#error-cpf").text("");
            }

            animateCSS("#step-2", 'bounceOut').then((message) => {
                $("#step-2").addClass("none");
                if (step == "next") {
                    $("#step-3").removeClass("none");
                    animateCSS("#step-3", 'bounceInRight');
                    $("#strip-2").removeClass("-gray");
                    $("#circle-3").removeClass("-gray");
                    $(".float-right").fadeOut();
                } else {
                    $("#step-1").removeClass("none");
                    animateCSS("#step-1", 'bounceInLeft');
                    $("#strip-1").addClass("-gray");
                    $("#circle-2").addClass("-gray");
                }
            });
        }

        if ($step3.is(":visible")) {
            animateCSS("#step-3", 'bounceOut').then((message) => {
                $("#step-3").addClass("none");
                $("#step-2").removeClass("none");
                animateCSS("#step-2", 'bounceInLeft');
                $("#strip-2").addClass("-gray");
                $("#circle-3").addClass("-gray");
                $(".float-right").fadeIn();
                $("#callback").html("");

            });
        }
    }

    // acao dos botoes de preenchimento
    $("#amount").on("change", function (e) {
        validateAmount();
        updateAmount();
    });

    // Botões passo 1 para incrementar quantidade de números
    $(".group-button a").on("click", function (e) {
        e.preventDefault();
        var step = $(this).data('value');
        var input = $("#amount");
        validateAmount();
        void 0;
        if (step == "+") {
            self.reserve.amount++;
        } else if (step == "-" && self.reserve.amount > 1) {
            self.reserve.amount--;
        } else if (step != "-" && step != "+") {
            self.reserve.amount += parseInt(step);
        }
        updateAmount();
        input.val(self.reserve.amount);
    });

    // Validando a quantidade (evitar zero ou vazio)
    function validateAmount() {
        var input = $("#amount");

        if (input.val() == "") {
            self.reserve.amount = 0;
        } else {
            self.reserve.amount = parseInt(input.val());
        }
        if (self.reserve.amount <= 0 || isNaN(self.reserve.amount)) {
            self.reserve.amount = 0;
        }

    }

    // atualizando quantidades em campos das etapas
    function updateAmount() {
        //self.reserve.total = self.utility.formatCurrency(self.reserve.amount * self.reserve.price);
        $("#pay-step-1 , #pay-step-2").text(self.reserve.total_pt);
        $("#pay-step").text("Total : " + self.reserve.total_pt);

        $("#amount-step-2").text(self.reserve.amount);
    }

    // Atualizando resumo dos resultados
    function updateResult() {

        self.reserve.name = $("#name").val();
        self.reserve.email = ($("#email").val()).toLowerCase();
        self.reserve.phone = $("#phone").val();
        self.reserve.cpf = $("#cpf").val();

        void 0;
        void 0;
        void 0;
        void 0;

        //$("#res-contestId").text(": "+res_contestId);
        // $("#res-prize").text(": "+res_prize);

        $("#res-reserve").text(": #" + self.reserve.hash);
        $("#res-name").text(": " + self.reserve.name);
        $("#res-email").text(": " + self.reserve.email);
        $("#res-phone").text(": " + self.reserve.phone);
        $("#res-cpf").text(": " + self.reserve.cpf);

        if (self.reserve.amount <= 1) {
            $("#res-amount").text(": " + self.reserve.amount + " número será reservado");
        } else {
            $("#res-amount").text(": " + self.reserve.amount + " números serão reservados");
        }
        $("#res-total").text(": " + self.reserve.total_pt);
    }

    // acao dos botoes de preenchimento
    $("#checkout").on("click", function (e) {
        $("#callback").html("");
        checkout();
    });

    function checkout() {
        //    $url = "<?= url("{ $contest -> keyword } /checkout"); ?>";
        var load = $("[data-modal-loading]");
        var flashClass = "ajax_response";
        var flash = $("." + flashClass);

        $.ajax({
            method: "POST",
            url: self.url + "/checkout",
            dataType: "json",
            // data: {
            //     reserve_client: res_reserve_client,
            //     name: res_name,
            //     email: res_email,
            //     phone: res_phone,
            //     amount: res_amount,
            //     again: res_again
            // },
            data: self.reserve,
            beforeSend: function () {
                void 0;
                load.find("[data-info]").html("Processando...");
                load.fadeIn('fast');
            },
            success: function (response) {
                void 0;
                void 0;

                //redirect
                if (response.redirect) {
                    window.location.href = response.redirect;
                }

                //reload
                if (response.reload) {
                    location.reload();
                }

                //message
                if(response.message) {

                    self.utility.ajaxMessage(response.message, 3);
                    if (response.info) {
                        void 0;
                        $("#callback").html(response.info);
                        load.fadeOut('fast');
                    }
                    if (response.amount) {
                        void 0;
                        $("#amount").val(response.amount);
                        validateAmount();
                        updateAmount();
                        updateResult();
                        self.reserve.again = true;   //  avisa que é um reenvio de dados
                        $(".group-float").remove();
                        load.fadeOut('fast');
                    }
                }

                if(response.pix.software_house){
                    void 0;

                    $(".group-float").fadeOut();
                    $(".group-payment").fadeOut();

                    animateCSS("#step-3", 'bounceOut').then((message) => {
                        $("#step-3").addClass("none");
                        $("#strip-3").removeClass("-gray");
                        $("#circle-4").removeClass("-gray");
                        $("#callback").html("");
                        $("#step-4").removeClass("none");
                    });

                    self.utility.ajaxMessage("<div class='message info icon-info'>Validando código para pagamento, aguarde...</div>", 3);
                    requestValidadePix(response.pix.txid);


                }else if(response.pix){

                    void 0;

                    $(".group-float").fadeOut();
                    $(".group-payment").fadeOut();
                    //console.log(response.pix.qrcode);
                    
                    animateCSS("#step-3", 'bounceOut').then((message) => {
                        $("#step-3").addClass("none");
                        $("#strip-3").removeClass("-gray");
                        $("#circle-4").removeClass("-gray");
                        $("#callback").html("");
                        $("#step-4").removeClass("none");
                    });
                   
                    if((response.pix.txid).length > 4){
                        requestPix(response.pix.txid);
                    }else{
                        void 0;
                        $("#title-step-4").html("Não foi possível gerar o PIX <br><span class='cf-text-2 --gray normal'> tente novamente mais tarde</span>");
                    }

                    $("#qrcode-pix").html(response.pix.qrcode);
                    $("#cod-pix").val(response.pix.pix);
                    $("#time").html(response.pix.time);
                    $("#expire_in").val(response.pix.expire_in);
                    load.fadeOut('fast');

                }
            },
            complete: function (response) {
                void 0;

            },
            error: function (event) {
                load.fadeOut('fast', function () {
                    self.utility.ajaxMessage("<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição... fn:checkout</div>", 5);
                });
            }

        });
    }



    function requestPix(txid) {

        setTimeout(() => {
            void 0
            var interval_request = setInterval(function () {
                $.ajax({
                    method: "POST",
                    url: self.url_req,
                    dataType: "json",
                    data: {
                        txid: txid,
                    },
                    beforeSend: function () {
                        void 0;
                    },
                    success: function (response) {
                        void 0;
                        void 0;

                        //redirect
                        if (response.redirect) {
                            window.location.href = response.redirect;
                        }

                        //reload
                        if (response.reload) {
                            location.reload();
                        }

                        //message
                        if (response.message) {
                            self.utility.ajaxMessage(response.message, 3);

                        }

                        $val = $("#expire_in").val();
                        $expire_in = new Date($val).getTime();

                        if ($expire_in < new Date().getTime()) {
                            void 0;
                            $("#step-4 .content").html("");
                            $("#time-is-over").removeClass("none");
                            clearInterval(interval_request);
                        } else {
                            //console.log($expire_in - new Date().getTime());
                        }


                    },
                    complete: function (response) {
                        void 0;

                    },
                    error: function (event) {

                        self.utility.ajaxMessage("<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição... fn:requestPix</div>", 5);

                    }

                });

            }, self.time_req);
        }, self.start_req);

    }

    // Verifica se o PIX já está como ativo na TecnoSpeed
    function requestValidadePix(txid){
       
        $count = 0;
        var load = $("[data-modal-loading]");
        load.find("[data-info]").html("Aguardando validação do Banco");
        load.fadeIn('fast');

        setTimeout(() => {
            void 0;
            void 0;

            var interval_requestValidaPix = setInterval(function () {
                $.ajax({
                    method: "GET",
                    url: self.url_pix_qrcode + "/" + txid,
                    dataType: "json",
                    beforeSend: function () {
                        void 0;
                    },
                    success: function (response) {
                        void 0;
                        void 0;

                        $count ++;
                        void 0;

                        if(response.pix){

                            if(response.pix.code == 200){
                               
                                $("#qrcode-pix").html(response.pix.qrcode);
                                $("#cod-pix").val(response.pix.pix);
                                $("#time").html(response.pix.time);
                                $("#expire_in").val(response.pix.expire_in);

                                clearInterval(interval_requestValidaPix);
                                load.fadeOut('fast');
                                requestPix(txid);

                            }else if($count > 7){
                                clearInterval(interval_requestValidaPix);
                                void 0;
                                load.fadeOut('fast');
                                $("#title-step-4").html("Não foi possível gerar o PIX <br><span class='cf-text-2 --gray normal'> tente novamente mais tarde</span>");
                            
                            }
        
                        }

                    },
                    complete: function (response) {
                        void 0;

                    },
                    error: function (event) {

                        load.fadeOut('fast', function () {
                            self.utility.ajaxMessage("<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição... fn:requestValidadePix</div>", 5);
                        });

                    }

                });

            }, 3000);
        }, 1000);
    }

}
